<template>
  <div id="inspectionMeltblown">
    <el-dialog
      :title="inspectionMeltblownFormTitle"
      width="1200px"
      :visible.sync="inspectionMeltblownDialogVisible"
      :close-on-click-modal="false"
      @close="inspectionMeltblownDialogClose"
    >
      <el-form
        ref="inspectionMeltblownFormRef"
        :model="inspectionMeltblownForm"
        :rules="inspectionMeltblownFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="inspectionMeltblownForm.productName" placeholder="请输入产品名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="批号" prop="batchNo">
              <el-input v-model="inspectionMeltblownForm.batchNo" placeholder="请输入批号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="型号/规格" prop="spec">
              <el-input v-model="inspectionMeltblownForm.spec" placeholder="请输入型号/规格" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="取样量" prop="sampleSize">
              <el-input v-model="inspectionMeltblownForm.sampleSize" placeholder="请输入取样量" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检验日期" prop="inspectionDate">
              <el-date-picker v-model="inspectionMeltblownForm.inspectionDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <span>无纺/熔喷布</span>
        <span>外观:</span>
        <el-divider content-position="center">标准:均匀、平整、无明显折痕和死折；干燥、清洁，无污染和杂质；无异常气味。</el-divider>
        <el-row>
          <el-col :span="24">
            <el-form-item label="样品名称" prop="meltblownExteriorName">
              <el-radio-group v-model="inspectionMeltblownForm.meltblownExteriorName">
                <el-radio :label="1">
                  蓝色无纺布
                </el-radio>
                <el-radio :label="2">
                  白色无纺布
                </el-radio>
                <el-radio :label="3">
                  熔喷布
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品1：" prop="meltblownExteriorResult1">
              <el-radio-group v-model="inspectionMeltblownForm.meltblownExteriorResult1">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品2：" prop="meltblownExteriorResult2">
              <el-radio-group v-model="inspectionMeltblownForm.meltblownExteriorResult2">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品3：" prop="meltblownExteriorResult3">
              <el-radio-group v-model="inspectionMeltblownForm.meltblownExteriorResult3">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider><i class="el-icon-mobile-phone"></i></el-divider>
        <span>幅宽</span>
        <el-divider content-position="center">标准：白色无纺布19.5cm±0.5cm 蓝色无纺布17.5cm±0.5cm 熔喷布17.5cm±0.5cm</el-divider>
        <el-row>
          <el-col :span="24">
            <el-form-item label="样品名称" prop="meltblownWidthName">
              <el-radio-group v-model="inspectionMeltblownForm.meltblownWidthName">
                <el-radio :label="1">
                  蓝色无纺布
                </el-radio>
                <el-radio :label="2">
                  白色无纺布
                </el-radio>
                <el-radio :label="3">
                  熔喷布
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品1：" prop="meltblownWidthValue1">
              <el-input v-model="inspectionMeltblownForm.meltblownWidthValue1" placeholder="请输入幅宽" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品2：" prop="meltblownWidthValue2">
              <el-input v-model="inspectionMeltblownForm.meltblownWidthValue2" placeholder="请输入幅宽" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品3：" prop="meltblownWidthValue3">
              <el-input v-model="inspectionMeltblownForm.meltblownWidthValue3" placeholder="请输入幅宽" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品1：" prop="meltblownWidthResult1">
              <el-radio-group v-model="inspectionMeltblownForm.meltblownWidthResult1">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品2：" prop="meltblownWidthResult2">
              <el-radio-group v-model="inspectionMeltblownForm.meltblownWidthResult2">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品3：" prop="meltblownWidthResult3">
              <el-radio-group v-model="inspectionMeltblownForm.meltblownWidthResult3">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider><i class="el-icon-mobile-phone"></i></el-divider>

        <span>克重</span>
        <el-divider content-position="center">标准：单位面积质量≥15g/㎡</el-divider>
        <el-row>
          <el-col :span="24">
            <el-form-item label="样品名称" prop="meltblownWeightName">
              <el-radio-group v-model="inspectionMeltblownForm.meltblownWeightName">
                <el-radio :label="1">
                  蓝色无纺布
                </el-radio>
                <el-radio :label="2">
                  白色无纺布
                </el-radio>
                <el-radio :label="3">
                  熔喷布
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品1：" prop="meltblownWeightValue1">
              <el-input v-model="inspectionMeltblownForm.meltblownWeightValue1" placeholder="请输入克重" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品2：" prop="meltblownWeightValue2">
              <el-input v-model="inspectionMeltblownForm.meltblownWeightValue2" placeholder="请输入克重" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品3：" prop="meltblownWeightValue3">
              <el-input v-model="inspectionMeltblownForm.meltblownWeightValue3" placeholder="请输入克重" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品1：" prop="meltblownWeightResult1">
              <el-radio-group v-model="inspectionMeltblownForm.meltblownWeightResult1">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品2：" prop="meltblownWeightResult2">
              <el-radio-group v-model="inspectionMeltblownForm.meltblownWeightResult2">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="样品3：" prop="meltblownWeightResult3">
              <el-radio-group v-model="inspectionMeltblownForm.meltblownWeightResult3">
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注" prop="meltblownRemarks">
              <el-input
                v-model="inspectionMeltblownForm.meltblownRemarks"
                placeholder="请输入备注"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="检验人" prop="inspector">
              <el-input v-model="inspectionMeltblownForm.inspector" placeholder="请输入检验人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="检验操作日期" prop="operationDate">
              <el-date-picker v-model="inspectionMeltblownForm.operationDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="审核人" prop="reviewer">
              <el-input v-model="inspectionMeltblownForm.reviewer" placeholder="请输入审核人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="审核时间" prop="reviewDate">
              <el-date-picker v-model="inspectionMeltblownForm.reviewDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        
      </el-form>
      <div slot="footer">
        <el-button @click="inspectionMeltblownDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="inspectionMeltblownFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="部门">
        <el-input v-model="searchForm.dept" placeholder="请输入部门" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="inspectionMeltblownPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="batchNo" label="批号" />
      <el-table-column prop="spec" label="型号/规格" />
      <el-table-column prop="sampleSize" label="取样量" />
      <el-table-column prop="inspectionDate" label="检验日期" />
      <el-table-column prop="inspector" label="检验人" />
      <el-table-column prop="operationDate" label="检验操作日期" />
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column prop="reviewDate" label="复核日期" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="inspectionMeltblownPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addInspection, deleteInspection, updateInspection, selectInspectionInfo, selectInspectionList } from '@/api/quality/rawMaterialInspection'

export default {
  data () {
    return {
      inspectionMeltblownDialogVisible: false,
      inspectionMeltblownFormTitle: '',
      inspectionMeltblownForm: {
        id: '',
        productName: '',
        batchNo: '',
        spec: '',
        sampleSize: '',
        inspectionDate: '',
        meltblownExteriorName: '',
        meltblownExteriorResult1: '',
        meltblownExteriorResult2: '',
        meltblownExteriorResult3: '',
        meltblownWidthName: '',
        meltblownWidthResult1: '',
        meltblownWidthResult2: '',
        meltblownWidthResult3: '',
        meltblownWidthValue1: '',
        meltblownWidthValue2: '',
        meltblownWidthValue3: '',
        meltblownWeightName: '',
        meltblownWeightResult1: '',
        meltblownWeightResult2: '',
        meltblownWeightResult3: '',
        meltblownWeightValue1: '',
        meltblownWeightValue2: '',
        meltblownWeightValue3: '',
        inspector: '',
        operationDate: '',
        reviewer: '',
        reviewDate: '',
        meltblownRemarks: '',
        type: '3'
      },
      inspectionMeltblownFormRules: {
        productName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      inspectionMeltblownPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        type: '3',
        dept: ''
      },
      resultList: [
        { label: '符合规定', value: '1' },
        { label: '不符合规定', value: '2' }
      ]
    }
  },
  created () {
    selectInspectionList(this.searchForm).then(res => {
      this.inspectionMeltblownPage = res
    })
  },
  methods: {
    inspectionMeltblownDialogClose () {
      this.$refs.inspectionMeltblownFormRef.resetFields()
      this.inspectionMeltblownForm.id = ''
    },
    inspectionMeltblownFormSubmit () {
      if (this.inspectionMeltblownFormTitle === '(无纺布、熔喷布)一次性使用医用口罩原材料检验记录详情') {
        this.inspectionMeltblownDialogVisible = false
        return
      }
      this.$refs.inspectionMeltblownFormRef.validate(async valid => {
        if (valid) {
          
          if (this.inspectionMeltblownFormTitle === '新增(无纺布、熔喷布)一次性使用医用口罩原材料检验记录') {
            await addInspection(this.inspectionMeltblownForm)
          } else if (this.inspectionMeltblownFormTitle === '修改(无纺布、熔喷布)一次性使用医用口罩原材料检验记录') {
            await updateInspection(this.inspectionMeltblownForm)
          }
          this.inspectionMeltblownPage = await selectInspectionList(this.searchForm)
          this.inspectionMeltblownDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.inspectionMeltblownFormTitle = '新增(无纺布、熔喷布)一次性使用医用口罩原材料检验记录'
      this.inspectionMeltblownDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteInspection(row.id)
        if (this.inspectionMeltblownPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.inspectionMeltblownPage = await selectInspectionList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.inspectionMeltblownFormTitle = '修改(无纺布、熔喷布)一次性使用医用口罩原材料检验记录'
      this.inspectionMeltblownDialogVisible = true
      this.selectInspectionInfoById(row.id)
    },
    handleInfo (index, row) {
      this.inspectionMeltblownFormTitle = '(无纺布、熔喷布)一次性使用医用口罩原材料检验记录详情'
      this.inspectionMeltblownDialogVisible = true
      this.selectInspectionInfoById(row.id)
    },
    selectInspectionInfoById (id) {
      selectInspectionInfo(id).then(res => {
        this.inspectionMeltblownForm.id = res.id
        this.inspectionMeltblownForm.productName = res.productName
        this.inspectionMeltblownForm.batchNo = res.batchNo
        this.inspectionMeltblownForm.spec = res.spec
        this.inspectionMeltblownForm.sampleSize = res.sampleSize
        this.inspectionMeltblownForm.inspectionDate = res.inspectionDate
        this.inspectionMeltblownForm.meltblownExteriorName = res.meltblownExteriorName
        this.inspectionMeltblownForm.meltblownExteriorResult1 = res.meltblownExteriorResult1
        this.inspectionMeltblownForm.meltblownExteriorResult2 = res.meltblownExteriorResult2
        this.inspectionMeltblownForm.meltblownExteriorResult3 = res.meltblownExteriorResult3
        this.inspectionMeltblownForm.meltblownWidthName = res.meltblownWidthName
        this.inspectionMeltblownForm.meltblownWidthResult1 = res.meltblownWidthResult1
        this.inspectionMeltblownForm.meltblownWidthResult2 = res.meltblownWidthResult2
        this.inspectionMeltblownForm.meltblownWidthResult3 = res.meltblownWidthResult3
        this.inspectionMeltblownForm.meltblownWidthValue1 = res.meltblownWidthValue1
        this.inspectionMeltblownForm.meltblownWidthValue2 = res.meltblownWidthValue2
        this.inspectionMeltblownForm.meltblownWidthValue3 = res.meltblownWidthValue3
        this.inspectionMeltblownForm.meltblownWeightName = res.meltblownWeightName
        this.inspectionMeltblownForm.meltblownWeightResult1 = res.meltblownWeightResult1
        this.inspectionMeltblownForm.meltblownWeightResult2 = res.meltblownWeightResult2
        this.inspectionMeltblownForm.meltblownWeightResult3 = res.meltblownWeightResult3
        this.inspectionMeltblownForm.meltblownWeightValue1 = res.meltblownWeightValue1
        this.inspectionMeltblownForm.meltblownWeightValue2 = res.meltblownWeightValue2
        this.inspectionMeltblownForm.meltblownWeightValue3 = res.meltblownWeightValue3
        this.inspectionMeltblownForm.meltblownRemarks = res.meltblownRemarks
        this.inspectionMeltblownForm.inspector = res.inspector
        this.inspectionMeltblownForm.operationDate = res.operationDate
        this.inspectionMeltblownForm.reviewer = res.reviewer
        this.inspectionMeltblownForm.reviewDate = res.reviewDate
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectInspectionList(this.searchForm).then(res => {
        this.inspectionMeltblownPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectInspectionList(this.searchForm).then(res => {
        this.inspectionMeltblownPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectInspectionList(this.searchForm).then(res => {
        this.inspectionMeltblownPage = res
      })
    }
  }
}
</script>

<style>
.vxe-select--panel {
  z-index: 3000 !important;
}
.vxe-input--panel {
  z-index: 3000 !important;
}
</style>
